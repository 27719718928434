import { Typography } from "@design-system-rt/rtk-ui-kit";
import styled from "styled-components";
import { MEDIA } from "../../constants/media";
import Content from "../Content/Content";
import Divider from "../Divider/Divider";

export const StyledTextContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;

  & > p {
    line-height: 20px;
    font-size: 14px;

    @media screen and ${MEDIA.fromTablet} {
      line-height: 28px;
      font-size: 16px;
    }
  }
`;

export const StyledNoteVerticalDivider = styled(Divider)`
  min-width: 2px;
  max-width: 2px;
  margin-right: 22px;
  height: inherit;
  background-color: rgb(232, 232, 238);
`;
export const StyledNoteHorizontalDivider = styled(Divider)`
  margin-bottom: 16px;
  min-height: 2px;
  max-height: 2px;
  background-color: rgb(232, 232, 238);
`;

export const StyledNoteContainer = styled.div`
  grid-column: span 2 / 13;

  @media screen and ${MEDIA.toTabletXl} {
    grid-column: 1 / 7;

    & ${StyledNoteHorizontalDivider} {
      display: none;
    }
  }

  @media screen and ${MEDIA.toTablet} {
    grid-column: 1 / 13;
  }

  @media screen and ${MEDIA.fromTabletXl} {
    & ${StyledNoteVerticalDivider} {
      display: none;
    }

    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const StyledNoteTextContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const StyledTextTypography = styled(Typography)`
  grid-column: 1 / 13;

  @media screen and ${MEDIA.fromTablet} {
    grid-column: 1 / 10;
  }
`;
export const StyledNoteTypography = styled(Typography)`
  color: rgba(16, 24, 40, 0.5);
`;

export const StyledContent = styled(Content)`
  @media screen and ${MEDIA.fromTabletXl} {
    display: none;
  }
`;
