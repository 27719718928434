import { useEffect, RefObject } from "react";

export const useClickAway = (
  refObject: RefObject<HTMLElement | null>,
  callback: () => void
): void => {
  useEffect(() => {
    const handleClick = (event: Event) => {
      if (
        refObject &&
        refObject.current &&
        !refObject.current.contains(event.target as Node)
      ) {
        if (callback) {
          callback();
        }
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [refObject, callback]);
};
