import React, { FC } from "react";
import { Switch, Redirect } from "react-router-dom";

const LegacyRoutes: FC = () => (
  <Switch>
    <Redirect from="/principles" to="/designsystem/principles" />
    <Redirect from="/structure" to="/designsystem/structure" />
    <Redirect from="/color" to="/basics/colors" />
    <Redirect from="/typography" to="/basics/typography" />
    <Redirect from="/grid" to="/basics/grid" />
    <Redirect from="/spacing" to="/basics/grid/spacings" />
    <Redirect from="/back_button%2Bheader" to="/components/back_btn" />
    <Redirect from="/breadcrumbs" to="/components/breadcrumbs" />
    <Redirect from="/button" to="/components/btn" />
    <Redirect from="/checkbox" to="/components/checkbox" />
    <Redirect from="/chips" to="/components/chips" />
    <Redirect from="/dropdown_menu" to="/components/dropdown_menu" />
    <Redirect from="/editable_text" to="/components/editable_text" />
    <Redirect
      from="/floating_action_button"
      to="/components/floating_action_button"
    />
    <Redirect from="/function_button" to="/components/function_button" />
    <Redirect from="/icon_button" to="/components/icon_button" />
    <Redirect from="/input" to="/components/input" />
    <Redirect from="/input_amount" to="/components/input_amount" />
    <Redirect from="/input_card" to="/components/input_card" />
    <Redirect from="/input_date" to="/components/input_date" />
    <Redirect from="/input_email" to="/components/input_email" />
    <Redirect
      from="/input_number_stepper"
      to="/components/input_number_stepper"
    />
    <Redirect
      from="/input_phone_number_ru"
      to="/components/input_phone_number"
    />
    <Redirect from="/multiselect" to="/components/multiselect" />
    <Redirect from="/notifications" to="/components/notification_inline" />
    <Redirect from="/picker_date" to="/components/picker_date" />
    <Redirect from="/picker_time" to="/components/picker_time" />
    <Redirect from="/radio_button" to="/components/radio_button" />
    <Redirect from="/loader" to="/components/loader" />
    <Redirect from="/scroll" to="/components/scroll" />
    <Redirect from="/select" to="/components/select" />
    <Redirect from="/slider" to="/components/slider" />
    <Redirect from="/stepper" to="/components/stepper" />
    <Redirect from="/sticker" to="/components/sticker" />
    <Redirect from="/switch" to="/components/switch" />
    <Redirect from="/tabs_button" to="/components/tabs_btn" />
    <Redirect from="/tabs_classic" to="/components/tabs_classic" />
    <Redirect from="/tags" to="/components/tags" />
    <Redirect from="/text_area" to="/components/textarea" />
    <Redirect from="/tooltip" to="/components/tooltip" />
    <Redirect from="/components_guide" to="/" />
    <Redirect from="/icons_guide" to="/" />
    <Redirect from="/culture" to="/" />
    <Redirect
      from="/validation_principles"
      to="/basics/validation/principles"
    />
    <Redirect from="/validation_forms" to="/basics/validation/fields" />
    <Redirect from="/levels_intro" to="/basics/interface/intro#depth" />
    <Redirect from="/levels_zero_level" to="/basics/interface/shadows#zero" />
    <Redirect from="/levels_shadows" to="/basics/interface/shadows" />
    <Redirect from="/levels_overlay" to="/basics/interface/overlay" />
    <Redirect from="/levels_hierarchy" to="/basics/interface/hierarchy" />
    <Redirect from="/levels_interaction" to="/basics/interface/interaction" />
    <Redirect
      from="/levels_default_levels"
      to="/basics/interface/shadows#components"
    />
    <Redirect
      from="/releases/release_2020_11_19"
      to="/history/announcement-2020-11-19"
    />
    <Redirect
      from="/releases/release_2020_09_02"
      to="/history/announcement-2020-09-02"
    />
    <Redirect
      from="/releases/release_2020_08_21"
      to="/history/announcement-2020-08-21"
    />
    <Redirect
      from="/releases/release_2020_07_23"
      to="/history/announcement-2020-07-23"
    />
    <Redirect
      from="/releases/release_2020_07_15"
      to="/history/announcement-2020-07-15"
    />
    <Redirect
      from="/releases/release_2020_07_08"
      to="/history/announcement-2020-07-08"
    />
    <Redirect
      from="/releases/release_2020_06_19"
      to="/history/announcement-2020-06-19"
    />
    <Redirect
      from="/releases/release_2020_06_15"
      to="/history/announcement-2020-06-15"
    />
    <Redirect
      from="/releases/release_2020_05_27"
      to="/history/announcement-2020-05-27"
    />
    <Redirect
      from="/releases/release_2020_05_20"
      to="/history/announcement-2020-05-20"
    />
    <Redirect
      from="/releases/release_2020_05_05"
      to="/history/announcement-2020-05-05"
    />
    <Redirect
      from="/releases/release_2020_04_23"
      to="/history/announcement-2020-04-23"
    />
    <Redirect
      from="/releases/release_2020_04_16"
      to="/history/announcement-2020-04-16"
    />
    <Redirect
      from="/releases/release_2020_04_10"
      to="/history/announcement-2020-04-10"
    />
    <Redirect
      from="/releases/release_2020_04_08"
      to="/history/announcement-2020-04-08"
    />
    <Redirect
      from="/releases/release_2020_04_07"
      to="/history/announcement-2020-04-07"
    />
    <Redirect
      from="/releases/release_2020_03_16"
      to="/history/announcement-2020-03-16"
    />
    <Redirect
      from="/releases/release_2020_03_10"
      to="/history/announcement-2020-03-10"
    />
  </Switch>
);

export default LegacyRoutes;
