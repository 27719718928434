import React, { FC, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import pagesConfig from "../constants/pagesConfig";
import PageLayout from "../components/PageLayout/PageLayout";
import ComingSoonPage from "../pages/ComingSoonPage";
import { IRoutesProps } from "../types/routes";
import Loader from "../components/Loader/Loader";
import { LOADER_TYPES } from "../constants/loader";
import LegacyRoutes from "./LegacyRoutes";
import ToolsPublicRoutes from "./ToolsPublicRoutes";

const PreviewPage = lazy(() => import("../pages/PreviewPage"));
const PresentationPage = lazy(() => import("../pages/PresentationPage"));
const PresentationRZDPage = lazy(() => import("../pages/PresentationRZDPage"));
const PresentationBasisPage = lazy(
  () => import("../pages/PresentationBasisPage")
);
const PresentationUMMCPage = lazy(
  () => import("../pages/PresentationUMMCPage")
);
const DesignsystemSectionRoutes = lazy(
  () => import("./DesignsystemSectionRoutes")
);
const BasicsSectionRoutes = lazy(() => import("./BasicsSectionRoutes"));
const ComponentsSectionRoutes = lazy(() => import("./ComponentsSectionRoutes"));
const EcosystemSectionRoutes = lazy(() => import("./EcosystemSectionRoutes"));
const HistorySectionRoutes = lazy(() => import("./HistorySectionRoutes"));
const AvatarMakerSectionRoutes = lazy(
  () => import("./AvatarMakerSectionRoutes")
);

const SECTIONS_ROUTES: Record<string, FC<IRoutesProps>> = {
  designsystem: DesignsystemSectionRoutes,
  basics: BasicsSectionRoutes,
  components: ComponentsSectionRoutes,
  experience: EcosystemSectionRoutes,
  history: HistorySectionRoutes,
  logo_maker: AvatarMakerSectionRoutes,
  tools_public: ToolsPublicRoutes,
};

const AppRoutes: FC = () => {
  return (
    <Router>
      <Suspense fallback={<Loader type={LOADER_TYPES.dark} />}>
        <Switch>
          <Route path="/preview" component={PreviewPage} />
          <Route path="/presentation-stage" component={PresentationPage} />
          <Route
            path="/presentation-stage-rzd"
            component={PresentationRZDPage}
          />
          <Route
            path="/presentation-stage-basis"
            component={PresentationBasisPage}
          />
          <Route
            path="/presentation-stage-ummc"
            component={PresentationUMMCPage}
          />
          <Redirect exact from="/" to="/preview" />
          <PageLayout>
            <Suspense fallback={<Loader type={LOADER_TYPES.page} />}>
              <Switch>
                {pagesConfig.map((sectionInfo) => {
                  const { id, path: sectionPath, generation } = sectionInfo;
                  if (generation && generation?.length > 1) {
                    return generation?.map((gen) => {
                      return (
                        <Route
                          key={`${id}-${gen}`}
                          path={`/${gen}${sectionPath}`}
                          render={(routeProps) => {
                            const Component =
                              SECTIONS_ROUTES[sectionPath.replace("/", "")] ||
                              ComingSoonPage;

                            return (
                              <Component
                                {...routeProps}
                                sectionInfo={sectionInfo}
                              />
                            );
                          }}
                        />
                      );
                    });
                  }

                  return (
                    <Route
                      key={id}
                      path={sectionPath}
                      render={(routeProps) => {
                        const Component =
                          SECTIONS_ROUTES[sectionPath.replace("/", "")] ||
                          ComingSoonPage;
                        return (
                          <Component
                            {...routeProps}
                            sectionInfo={sectionInfo}
                          />
                        );
                      }}
                    />
                  );
                })}
                <LegacyRoutes />
              </Switch>
            </Suspense>
          </PageLayout>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
