import React, { FC, useMemo, useCallback, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getNearestRoutesInfo } from "../../../utils/routes";
import LeftLongArrow from "../../Icons/LeftLongArrow";
import RightLongArrow from "../../Icons/RightLongArrow";
import {
  StyledContainer,
  StyledLeftBtn,
  StyledRightBtn,
  StyledBtnContent,
  StyledTextContainer,
  StyledSubsectionText,
  StyledSectionText,
  StyledDivider,
} from "./styles";
import { GenerationContext } from "../../../contexts/generationContext";
import { nonGenPaths } from "../../Sidebar/constants";

const FooterNavButtons: FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { generation: contextGen } = useContext(GenerationContext);
  const generationFromPathname = pathname.split("/")[1];

  const { prevRouteInfo, nextRouteInfo } = useMemo(
    () =>
      getNearestRoutesInfo(
        pathname,
        nonGenPaths.includes(generationFromPathname)
          ? contextGen
          : generationFromPathname
      ),
    [contextGen, generationFromPathname, pathname]
  );

  const handlePrev = useCallback(() => {
    if (prevRouteInfo && prevRouteInfo.generation?.length !== 0) {
      push(`/${contextGen}${prevRouteInfo.path}`);
    } else if (prevRouteInfo) {
      push(prevRouteInfo.path);
    }
  }, [contextGen, prevRouteInfo, push]);

  const handleNext = useCallback(() => {
    if (nextRouteInfo && nextRouteInfo.generation?.length !== 0) {
      push(`/${contextGen}${nextRouteInfo.path}`);
    } else if (nextRouteInfo) {
      push(nextRouteInfo.path);
    }
  }, [contextGen, nextRouteInfo, push]);

  return (
    <StyledContainer>
      <StyledLeftBtn onClick={handlePrev}>
        {prevRouteInfo ? (
          <StyledBtnContent>
            <LeftLongArrow />
            <StyledTextContainer>
              <StyledSubsectionText variant="accentL">
                {prevRouteInfo.pageName}
              </StyledSubsectionText>
              <StyledSectionText variant="caption">
                {prevRouteInfo.sectionName}
              </StyledSectionText>
            </StyledTextContainer>
          </StyledBtnContent>
        ) : null}
      </StyledLeftBtn>
      <StyledDivider />
      <StyledRightBtn onClick={handleNext}>
        {nextRouteInfo ? (
          <StyledBtnContent>
            <StyledTextContainer>
              <StyledSubsectionText variant="accentL">
                {nextRouteInfo.pageName}
              </StyledSubsectionText>
              <StyledSectionText variant="caption">
                {nextRouteInfo.sectionName}
              </StyledSectionText>
            </StyledTextContainer>
            <RightLongArrow />
          </StyledBtnContent>
        ) : null}
      </StyledRightBtn>
    </StyledContainer>
  );
};
FooterNavButtons.displayName = "FooterNavButtons";

export default FooterNavButtons;
