import React, { FC } from "react";
import Content from "../Content/Content";
import { PageLoaderPropsType } from "./types";
import { StyledHeader } from "./styles";

const PageLoader: FC<PageLoaderPropsType> = ({ text }) => {
  return (
    <>
      <StyledHeader />
      <Content spacing="xl">{text}</Content>
    </>
  );
};
PageLoader.displayName = "PageLoader";

export default PageLoader;
