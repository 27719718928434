import React, {
  MouseEvent,
  useCallback,
  useEffect,
  Fragment,
  useState,
  useContext,
} from "react";
import { useHistory, NavLink } from "react-router-dom";
import {
  StyledAccordionButton,
  StyledAccordionSection,
  StyledMenuItem,
  StyledMenuLink,
  StyledMenuList,
  StyledMenuSection,
  StyledAccordion,
} from "./styles";
import { ISidebarAccordion } from "./types";
import { GenerationContext } from "../../contexts/generationContext";
import { nonGenPaths } from "./constants";

const SidebarAccordion: React.FC<ISidebarAccordion> = ({
  pagesConfig,
  onCloseMenu,
}) => {
  const { generation: contextGeneration } = useContext(GenerationContext);
  const history = useHistory();
  const pathname = history?.location?.pathname;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pathGeneration: any = pathname.split("/")[1];
  const generation = nonGenPaths.includes(pathGeneration)
    ? contextGeneration
    : pathGeneration;

  const [activeSection, setActiveSection] = useState("");
  const [currentOpen, setCurrentOpen] = useState(pathname?.split("/")[2]);

  const toggleActiveSection = useCallback(
    (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      const section = (event.target as Element).getAttribute("data-id");
      const active = (event.target as Element).classList.contains("active");
      if (active) {
        setActiveSection("");
      } else if (section && !active) {
        setActiveSection(section);
      }
    },
    []
  );

  const handleMenuWithoutSubsections = useCallback(
    (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      toggleActiveSection(event);
      onCloseMenu();
    },
    [onCloseMenu, toggleActiveSection]
  );

  useEffect(() => {
    const section = pathname?.split("/")[2];
    if (nonGenPaths.includes(pathname?.split("/")[1])) {
      setCurrentOpen(pathname?.split("/")[1]);
    } else if (section) {
      setCurrentOpen(section);
    }
  }, [pathname]);

  return (
    <StyledAccordion>
      {pagesConfig.map(
        ({
          name: sectionName,
          subsectionsInfo,
          id: sectionId,
          path: sectionPath,
          sectionClassName = "",
        }) => {
          const activeClassName = () => {
            let className = "";
            if (activeSection === sectionId || currentOpen === sectionId) {
              className = "active";
            } else {
              className = "";
            }
            return className;
          };

          return (
            <StyledAccordionSection key={sectionId}>
              {subsectionsInfo.length ? (
                <>
                  <StyledAccordionButton
                    className={`accordion ${sectionClassName} ${activeClassName()}`}
                    onClick={toggleActiveSection}
                    data-id={sectionId}
                  >
                    {sectionName}
                  </StyledAccordionButton>
                  <StyledMenuList>
                    {subsectionsInfo?.map(
                      ({
                        name: subsectionName,
                        pagesInfo,
                        id: subsectionId,
                      }) => (
                        <Fragment key={subsectionId}>
                          {subsectionName && (
                            <StyledMenuSection>
                              {subsectionName}
                            </StyledMenuSection>
                          )}
                          {pagesInfo.map(
                            ({
                              name: pageName,
                              id: pageId,
                              path: pagePath,
                              generation: pageGeneration,
                            }) => {
                              if (pageGeneration?.includes(generation)) {
                                return (
                                  <StyledMenuItem key={pageId}>
                                    <StyledMenuLink
                                      onClick={onCloseMenu}
                                      to={`/${generation}${sectionPath}${pagePath}`}
                                    >
                                      {pageName}
                                    </StyledMenuLink>
                                  </StyledMenuItem>
                                );
                              }

                              if (pageGeneration?.length === 0) {
                                return (
                                  <StyledMenuItem key={pageId}>
                                    <StyledMenuLink
                                      onClick={onCloseMenu}
                                      to={`${sectionPath}${pagePath}`}
                                    >
                                      {pageName}
                                    </StyledMenuLink>
                                  </StyledMenuItem>
                                );
                              }

                              return null;
                            }
                          )}
                        </Fragment>
                      )
                    )}
                  </StyledMenuList>
                </>
              ) : (
                <NavLink to={`${sectionPath}/all`}>
                  <StyledAccordionButton
                    className={`accordion ${sectionClassName} ${activeClassName()}`}
                    onClick={handleMenuWithoutSubsections}
                    data-id={sectionId}
                  >
                    {sectionName}
                  </StyledAccordionButton>
                </NavLink>
              )}
            </StyledAccordionSection>
          );
        }
      )}
    </StyledAccordion>
  );
};

export default SidebarAccordion;
