import React, { FC, useState, useEffect, useRef } from "react";
import { LOADER_TYPES } from "../../constants/loader";
import PageLoader from "./PageLoader";
import DarkLoader from "./DarkLoader";
import { StyledContainer, StyledLine } from "./styles";
import { ILoader } from "./types";

const LOADER_COMPONENTS = {
  [LOADER_TYPES.dark]: DarkLoader,
  [LOADER_TYPES.page]: PageLoader,
};

const Loader: FC<ILoader> = ({ type, text = "Загрузка..." }) => {
  const interval = useRef(0);
  const [progress, setProgress] = useState(0);
  const Component = LOADER_COMPONENTS[type];

  useEffect(() => {
    interval.current = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress < 100 ? prevProgress + 1 : 0
      );
    }, 16);

    return () => {
      clearInterval(interval.current);
    };
  });

  return (
    <StyledContainer type={type}>
      <StyledLine progress={progress} />
      <Component text={text} />
    </StyledContainer>
  );
};
Loader.displayName = "Loader";

export default Loader;
