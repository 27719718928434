import React from "react";
import {
  StyledCopyright,
  StyledFeedback,
  StyledFooter,
  StyledMail,
} from "./styles";
import { currentYear } from "../../constants/currentYear";

const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <StyledCopyright>&copy; {currentYear} Ростелеком</StyledCopyright>
      <StyledFeedback href="mailto:design@rt.ru">
        <StyledMail>design@rt.ru</StyledMail>
      </StyledFeedback>
    </StyledFooter>
  );
};

export default Footer;
