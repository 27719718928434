import { createContext, MutableRefObject } from "react";

type PageLayoutContextType = {
  pageLayoutRef: MutableRefObject<HTMLDivElement | null>;
};

const initialState: PageLayoutContextType = {
  pageLayoutRef: {
    current: null,
  },
};

export default createContext(initialState);
