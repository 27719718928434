import styled, { css } from "styled-components";
import { Typography } from "@design-system-rt/rtk-ui-kit";
import { ITheme } from "@design-system-rt/rtk-ui-kit/types/theme";
import { MAX_PAGE_CONTENT_WIDTH, MEDIA } from "../../../constants/media";

const ICON_WIDTH = 80;
const MOBILE_ICON_WIDTH = 40;
const DESCTOP_SPACING = 40;

const generateBorderColor = () => "rgba(255,255,255, 0.1)";

const generateBaseBtn = () => css`
  display: flex;
  align-items: flex-end;
  width: 50%;
  cursor: pointer;
  max-width: ${MAX_PAGE_CONTENT_WIDTH / 2}px;
  position: relative;

  & svg {
    fill: ${({ theme }: { theme: ITheme }) => theme.greyScale[60]};
    position: absolute;
    top: 64px;
    bottom: 64px;
  }

  @media not all and (hover: none) {
    &:hover svg {
      fill: #9466ff;
    }
  }

  @media screen and ${MEDIA.toTablet} {
    & svg {
      top: 12px;
      bottom: auto;
      width: 40px;
    }
  }
`;

export const StyledContainer = styled.div`
  background: #1d1b1f;
  width: 100%;
  height: 208px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  border-bottom: 1px solid ${generateBorderColor};
  box-sizing: border-box;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    background: rgba(16, 24, 40, 0.05);
    top: -112px;
    @media screen and ${MEDIA.fromTablet} {
      content: none;
    }
  }

  @media screen and ${MEDIA.toTablet} {
    height: 108px;
  }

  @media screen and ${MEDIA.fromTabletXl} {
    border-left: 1px solid ${generateBorderColor};
    justify-content: center;
  }
`;

export const StyledBtnContent = styled.div`
  display: flex;
  align-items: flex-end;
  width: calc(100% - ${ICON_WIDTH + DESCTOP_SPACING}px);
  padding-bottom: 76px;

  @media screen and ${MEDIA.toTablet} {
    padding-bottom: 32px;
    width: calc(100% - ${MOBILE_ICON_WIDTH}px);
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:last-child {
    text-align: right;
  }
`;

export const StyledSubsectionText = styled(Typography)`
  color: rgba(255, 255, 255, 0.9);
  padding-bottom: 8px;
  user-select: none;

  @media screen and ${MEDIA.toTablet} {
    ${({ theme }: { theme: ITheme }) => theme.fonts.static.accentS}
  }

  @media screen and ${MEDIA.fromTablet} and ${MEDIA.toTabletXl} {
    padding-bottom: 16px;
  }
`;
export const StyledSectionText = styled(Typography)`
  color: rgba(255, 255, 255, 0.4);
  user-select: none;
`;

export const StyledLeftBtn = styled.div`
  ${generateBaseBtn}
  padding-right: 64px;
  justify-content: flex-end;

  @media not all and (hover: none) {
    &:hover ${StyledSubsectionText} {
      color: #9466ff;
    }
  }

  @media screen and ${MEDIA.fromTablet} {
    & svg {
      left: ${DESCTOP_SPACING}px;
    }
  }

  @media screen and ${MEDIA.toTablet} {
    padding-right: 12px;
    & svg {
      left: 10px;
    }
  }
`;
export const StyledRightBtn = styled.div`
  ${generateBaseBtn}
  padding-left: 64px;
  justify-content: flex-start;

  @media not all and (hover: none) {
    &:hover ${StyledSubsectionText} {
      color: #9466ff;
    }
  }

  @media screen and ${MEDIA.fromTablet} {
    & svg {
      right: ${DESCTOP_SPACING}px;
    }
  }

  @media screen and ${MEDIA.toTablet} {
    padding-left: 12px;
    & svg {
      right: 10px;
    }
  }
`;

export const StyledDivider = styled.div`
  width: 1px;
  background-color: ${generateBorderColor};
`;
