import React from "react";

const LeftLongArrow: React.FC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="1">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.9891 38.9986L10 38.9986V39.9986L67.9902 39.9986L56.9213 50.2669L57.6014 51L69.6039 39.8657L69.999 39.4991L69.6039 39.1325L57.6014 27.9982L56.9213 28.7313L67.9891 38.9986Z"
      />
    </g>
  </svg>
);

export default LeftLongArrow;
