import React, { FC } from "react";

const FigmaLogo: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <rect width="32" height="32" rx="16" fill="#101828" fillOpacity="0.05" />
    <g clipPath="url(#clip0_3970_33048)">
      <path
        d="M13.0827 24.3295C14.6927 24.3295 15.9993 23.085 15.9993 21.5517V18.7739H13.0827C11.4727 18.7739 10.166 20.0184 10.166 21.5517C10.166 23.085 11.4727 24.3295 13.0827 24.3295Z"
        fill="#0ACF83"
      />
      <path
        d="M10.166 15.9999C10.166 14.4666 11.4727 13.2222 13.0827 13.2222H15.9993V18.7777H13.0827C11.4727 18.7777 10.166 17.5333 10.166 15.9999Z"
        fill="#A259FF"
      />
      <path
        d="M10.166 10.4438C10.166 8.91046 11.4727 7.66602 13.0827 7.66602H15.9993V13.2216H13.0827C11.4727 13.2216 10.166 11.9771 10.166 10.4438Z"
        fill="#F24E1E"
      />
      <path
        d="M16.002 7.66602H18.9186C20.5286 7.66602 21.8353 8.91046 21.8353 10.4438C21.8353 11.9771 20.5286 13.2216 18.9186 13.2216H16.002V7.66602Z"
        fill="#FF7262"
      />
      <path
        d="M21.8353 15.9999C21.8353 17.5333 20.5286 18.7777 18.9186 18.7777C17.3086 18.7777 16.002 17.5333 16.002 15.9999C16.002 14.4666 17.3086 13.2222 18.9186 13.2222C20.5286 13.2222 21.8353 14.4666 21.8353 15.9999Z"
        fill="#1ABCFE"
      />
    </g>
    <defs>
      <clipPath id="clip0_3970_33048">
        <rect
          width="11.6667"
          height="16.6667"
          fill="white"
          transform="translate(10.166 7.66797)"
        />
      </clipPath>
    </defs>
  </svg>
);
FigmaLogo.displayName = "FigmaLogo";

export default FigmaLogo;
