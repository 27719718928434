import styled from "styled-components";
import { MAX_PAGE_CONTENT_WIDTH, MEDIA } from "../../../constants/media";
import Tabs from "../../Tabs/Tabs";
import Content from "../../Content/Content";
import {
  generatePadding,
  generateLeftMargin,
  generateRightGutter,
} from "./styledGenerators";

export const StyledRootContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.greyScale[5]};
`;
export const StyledContentContainer = styled.div`
  max-width: ${MAX_PAGE_CONTENT_WIDTH}px;
  width: 100%;
`;
export const StyledHeaderContainer = styled(Content)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media screen and ${MEDIA.toTablet} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  ${generatePadding}
`;
export const StyledHeaderContent = styled(Content)`
  flex-direction: row;
  display: flex;
  width: 100%;
`;

export const StyledBackBtn = styled.div`
  padding: 20px 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding-left: 40px;

  @media screen and ${MEDIA.toTablet} {
    padding-left: 20px;
  }

  & > p {
    padding-left: 4px;
  }

  @media not all and (hover: none) {
    &:hover > p {
      color: rgba(119, 0, 255, 1);
    }

    &:hover > svg {
      fill: rgba(119, 0, 255, 1);
    }
  }
`;

export const StyledTabsContainer = styled.div`
  position: relative;
`;

export const StyledTabs = styled(Tabs)`
  & p:first-child {
    ${generateLeftMargin}
  }

  & p:last-child {
    ${generateRightGutter}
  }
`;
