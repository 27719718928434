import React, { FC } from "react";
import { IContentText } from "./types";
import {
  StyledTextContainer,
  StyledNoteContainer,
  StyledNoteTextContainer,
  StyledTextTypography,
  StyledNoteTypography,
  StyledContent,
  StyledNoteHorizontalDivider,
  StyledNoteVerticalDivider,
} from "./styles";

const ContentText: FC<IContentText> = ({ children, noteText, style }) => {
  return (
    <StyledTextContainer>
      <StyledTextTypography variant="bodyL" style={style}>
        {children}
      </StyledTextTypography>
      {noteText ? (
        <StyledNoteContainer>
          <StyledContent spacingTop="l" />
          <StyledNoteHorizontalDivider type="horizontal" />
          <StyledNoteTextContainer>
            <StyledNoteVerticalDivider type="vertical" />
            <StyledNoteTypography variant="caption">
              {noteText}
            </StyledNoteTypography>
          </StyledNoteTextContainer>
        </StyledNoteContainer>
      ) : null}
    </StyledTextContainer>
  );
};

export default ContentText;
