import React, { FC, lazy } from "react";
import { Switch } from "react-router-dom";
import RenderPageRoutes from "../components/RenderPageRoutes/RenderPageRoutes";
import { IRoutesProps, PageslistType } from "../types/routes";

const PAGES_LIST: PageslistType = {
  avatarmaker: lazy(
    () => import("../pages/toolsSection/AvatarMakerPage/AvatarMakerPage")
  ),
  arti: lazy(() => import("../pages/toolsSection/ArtiPage/ArtiPage")),
};

const ToolsPublicRoutes: FC<IRoutesProps> = ({ sectionInfo }) => (
  <Switch>
    <RenderPageRoutes sectionInfo={sectionInfo} pagesList={PAGES_LIST} />
  </Switch>
);

export default ToolsPublicRoutes;
