export enum THEME {
  light = "light",
  dark = "dark",
}

export enum THEME_NAME {
  default = "default",
  purple = "purple",
}

export const PAGE_HEADER_HEIGHT = 64;
export const PAGE_HEADER_MOBILE_HEIGHT = 56;

export enum SHAPES_LIST {
  circle = "circle",
  rounded = "rounded",
  geometric = "geometric",
}

export enum COLOR_LIST {
  orange = "orange",
  yellow = "yellow",
  green = "green",
  lightBlue = "lightBlue",
  blue = "blue",
  darkBlue = "darkBlue",
  purple = "purple",
  white = "white",
  black = "black",
  red = "red",
  cyan = "cyan",
  gray = "gray",
}
