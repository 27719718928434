import React, { useState } from "react";

import { ThemeProvider } from "@design-system-rt/rtk-ui-kit";
import { THEMES } from "@design-system-rt/rtk-ui-kit/constants/components";
import { ThemeNamesType } from "@design-system-rt/rtk-ui-kit/types/theme";
import AppRoutes from "./routes/AppRoutes";

import "./styles/atomaro-purple-dark-all.css";
import "./styles/atomaro-default-light-all.css";
import "@atomaro-rt/theme-rostelecom/atomaro-purple-light-all.css";

import GenerationContextProvider, {
  GENERATIONS_ARR,
  GENERATIONS_MAP,
  IGenerationContext,
  TGenerationsMap,
} from "./contexts/generationContext";

const App: React.FC = () => {
  const theme =
    (localStorage.getItem("theme") as ThemeNamesType) || THEMES.light;
  const [generation, setGeneration] = useState<TGenerationsMap>(
    GENERATIONS_MAP.gen2
  );

  const generationContextValue: IGenerationContext = {
    generation,
    updateGeneration: (v: TGenerationsMap) => {
      if (v !== generation && GENERATIONS_ARR.includes(v)) {
        setGeneration(v);
      }
    },
  };

  return (
    <ThemeProvider themeName={theme}>
      <GenerationContextProvider value={generationContextValue}>
        <AppRoutes />
      </GenerationContextProvider>
    </ThemeProvider>
  );
};

export default App;
