import styled, { css } from "styled-components";
import { SCROLL_SIZE } from "../../constants/scroll";
import { MEDIA } from "../../constants/media";
import { ContainerPropsType } from "./types";

const generateContainerHeight = ({ height }: ContainerPropsType) => css`
  height: ${height ? `${height - SCROLL_SIZE}px` : "auto"};
`;

export const StyledContainer = styled.div<ContainerPropsType>`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
  width: 100%;
  ${generateContainerHeight};
  min-height: 46px;
  @media screen and ${MEDIA.fromTabletXl} {
    min-height: 62px;
  }
`;

export const StyledScrollContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  position: absolute;
  width: 100%;
  padding-bottom: ${SCROLL_SIZE}px;
`;

export const StyledTabSpacing = styled.span`
  padding: 0 24px;

  @media screen and ${MEDIA.toTablet} {
    padding: 0 12px;
  }
`;
