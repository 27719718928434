import React, { FC } from "react";
import Divider from "../../Divider/Divider";
import FooterLikes from "../FooterLikes/FooterLikes";
import FooterNavButtons from "../FooterNavButtons/FooterNavButtons";
import { StyledRootContainer } from "./styles";

const Footer: FC = () => {
  return (
    <StyledRootContainer>
      <Divider />
      <FooterLikes />
      <FooterNavButtons />
    </StyledRootContainer>
  );
};
Footer.displayName = "ContentLayoutFooter";

export default Footer;
