import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LINK_TYPES } from "../constants/link";

type UseLinkReturnType = () => void;

export const useLink = (
  link: string,
  linkType: keyof typeof LINK_TYPES
): UseLinkReturnType => {
  const history = useHistory();
  const handleRedirect = useCallback(() => {
    if (!link) {
      return;
    }
    if (linkType === LINK_TYPES.outer) {
      window.open(link, "_blank");
    } else if (linkType === LINK_TYPES.inner) {
      history.push(link);
    }
  }, [link, linkType, history]);

  return handleRedirect;
};
