import styled from "styled-components";

export const StyledHorizontalDivider = styled.div`
  min-height: 1px;
  max-height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.transparent[5]};
`;

export const StyledVerticalDivider = styled.div`
  min-width: 1px;
  max-width: 1px;
  background-color: ${({ theme }) => theme.transparent[5]};
`;
