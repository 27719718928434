import styled, { css } from "styled-components";
import { Dislike, Like, Typography } from "@design-system-rt/rtk-ui-kit";
import { ITheme } from "@design-system-rt/rtk-ui-kit/types/theme";
import { MAX_PAGE_CONTENT_WIDTH, MEDIA } from "../../../constants/media";

const generateIconColor = ({ theme }: { theme: ITheme }) => css`
  fill: ${theme.baseColors.primary1(1)};
`;

export const StyledRootContainer = styled.div`
  padding: 64px;
  display: flex;
  justify-content: space-between;
  max-width: ${MAX_PAGE_CONTENT_WIDTH}px;
  width: 100%;

  @media screen and ${MEDIA.toTablet} {
    padding: 32px;
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledLikesContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  @media screen and ${MEDIA.toTabletXl} {
    flex-direction: column;
  }
`;
export const StyledBtnsContainer = styled.div`
  display: inline-flex;

  @media screen and ${MEDIA.toTablet} {
    margin-top: 20px;
    margin-bottom: 32px;
  }

  @media screen and ${MEDIA.toTabletXl} {
    margin-top: 16px;
  }
`;
export const StyledLikeBtn = styled.div<{ active: boolean }>`
  width: 120px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background-color: ${({ theme, active }) =>
    active ? theme.baseColors.primary1(0.15) : theme.baseColors.primary1(0.05)};

  @media not all and (hover: none) {
    &:hover {
      background-color: ${({ theme }) => theme.baseColors.primary1(0.1)};
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.baseColors.primary1(0.15)};
  }
`;

export const StyledBtnSpacing = styled.div`
  padding: 0 8px;
`;

export const StyledBtnTypography = styled(Typography)`
  padding-right: 8px;
`;

export const StyledMaterialTextTypography = styled(Typography)`
  padding-right: 24px;
  white-space: nowrap;

  @media screen and ${MEDIA.toTabletXl} {
    padding-right: 0;
  }
`;

export const StyledTextTypography = styled(Typography)`
  padding-right: 24px;
  white-space: nowrap;
`;

export const StyledLike = styled(Like)`
  ${generateIconColor}
`;
export const StyledDislike = styled(Dislike)`
  ${generateIconColor}
`;

export const StyledShareIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: ${({ theme }) => theme.transparent[5]};
`;

export const StyledShareBtn = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 32px;
  -webkit-tap-highlight-color: transparent;

  @media screen and ${MEDIA.fromTablet} {
    padding-top: 0;
  }

  @media not all and (hover: none) {
    &:hover > ${StyledShareIcon} {
      background-color: ${({ theme }) => theme.transparent[10]};
    }
  }

  &:active > ${StyledShareIcon} {
    background-color: ${({ theme }) => theme.transparent[15]};
  }
`;

export const StyledVerticalDividerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 24px;

  @media screen and ${MEDIA.toTablet} {
    display: none;
  }
`;
