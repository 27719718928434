import { useState } from "react";

type UseVisibleStateReturnType = {
  show: boolean;
  open: () => void;
  hide: () => void;
  toggleShow: () => void;
};

export const useVisibleState = (initial = false): UseVisibleStateReturnType => {
  const [show, setShow] = useState(initial);
  const open = () => {
    setShow(true);
  };
  const hide = () => {
    setShow(false);
  };
  const toggleShow = () => setShow((value) => !value);
  return { show, open, hide, toggleShow };
};
