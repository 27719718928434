import { useState, ChangeEvent } from "react";

type UseInputReturnType = {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  clear: () => void;
};

export const useInput = (initial: string): UseInputReturnType => {
  const [value, setValue] = useState(initial);
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const clear = () => {
    setValue("");
  };
  return { value, onChange, clear };
};
