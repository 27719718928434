import React, { FC } from "react";

const VueLogo: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <rect width="32" height="32" rx="16" fill="#41B883" fillOpacity="0.2" />
    <g clipPath="url(#clip0_831_55771)">
      <path
        d="M18.0204 8.9165L15.9998 12.4767L13.9791 8.9165H7.25L15.9998 24.333L24.7496 8.9165H18.0204Z"
        fill="#41B883"
      />
      <path
        d="M18.0205 8.91629L15.9999 12.4765L13.9792 8.91629H10.75L15.9999 18.166L21.2497 8.91629H18.0205Z"
        fill="#34495E"
      />
    </g>
    <defs>
      <clipPath id="clip0_831_55771">
        <rect
          width="17.5"
          height="15.4167"
          fill="white"
          transform="translate(7.25 8.9165)"
        />
      </clipPath>
    </defs>
  </svg>
);
VueLogo.displayName = "VueLogo";

export default VueLogo;
