import React, { FC } from "react";
import FigmaLogoIcon from "../../Icons/FigmaLogo";
import ReactLogoIcon from "../../Icons/ReactLogo";
import VueLogoIcon from "../../Icons/VueLogo";
import { StyledContainer, StyledLinkBtn } from "./styles";
import { ILinkBtnsGroup } from "./types";

const LinkBtnsGroup: FC<ILinkBtnsGroup> = ({
  storybookLink,
  storybookVueLink,
  figmaLink,
  gitlabLink,
}) => {
  if (!storybookLink && !storybookVueLink && !figmaLink && !gitlabLink) {
    return null;
  }

  return (
    <StyledContainer>
      {storybookLink ? (
        <StyledLinkBtn link={storybookLink} title="Компоненты в Storybook">
          <ReactLogoIcon />
        </StyledLinkBtn>
      ) : null}
      {storybookVueLink ? (
        <StyledLinkBtn link={storybookVueLink} title="Компоненты в Storybook">
          <VueLogoIcon />
        </StyledLinkBtn>
      ) : null}
      {figmaLink ? (
        <StyledLinkBtn link={figmaLink} title="Библиотека в Figma">
          <FigmaLogoIcon />
        </StyledLinkBtn>
      ) : null}
    </StyledContainer>
  );
};

LinkBtnsGroup.displayName = "LinkBtnsGroup";

export default LinkBtnsGroup;
