export const getScrollSize = (): number => {
  let scrollSize = 0;
  const div = document.createElement("div");

  div.style.overflow = "scroll";
  div.style.width = "100px";
  div.style.height = "100px";

  document.body.appendChild(div);
  scrollSize = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);

  return scrollSize;
};
