import React, { FC, useEffect, useRef, useState } from "react";
import { Sticky } from "react-sticky";
import {
  StyledStickyContainer,
  StyledTabsRootContainer,
  StyledTabsContainer,
} from "./styles";

const StickyTabsContainer: FC = ({ children }) => {
  const tabsContainerRef = useRef<HTMLDivElement | null>(null);
  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    const page = document.getElementById("container");
    const handler = () => {
      if (tabsContainerRef.current) {
        setSticky(tabsContainerRef.current?.getBoundingClientRect().y <= 0);
      }
    };
    if (tabsContainerRef.current) {
      page?.addEventListener("scroll", handler);
    }
    return () => page?.removeEventListener("scroll", handler);
  }, []);
  return (
    <StyledStickyContainer ref={tabsContainerRef}>
      <Sticky relative>
        {({ style }) => (
          <StyledTabsRootContainer isSticky={sticky}>
            <StyledTabsContainer
              left={Number(style.left)}
              width={Number(style.width)}
            >
              {children}
            </StyledTabsContainer>
          </StyledTabsRootContainer>
        )}
      </Sticky>
    </StyledStickyContainer>
  );
};
StickyTabsContainer.displayName = "StickyTabsContainer";

export default StickyTabsContainer;
