import React, {
  RefObject,
  useRef,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react";
import { useHistory, useLocation } from "react-router-dom";

import { CloseLarge, Search } from "@design-system-rt/rtk-ui-kit";
import Select from "@atomaro/new-ui-kit/components/Select/Select";

import { useInput } from "../../hooks/useInput";
import { useClickAway } from "../../hooks/useClickAway";
import { StyledLogo, StyledLogoLink } from "../PageLayout/styles";
import Rostelecomlogo from "../Icons/Rostelecomlogo";
import { ISidebar } from "./types";
import SidebarAccordion from "./SidebarAccordion";
import {
  StyledSidebar,
  StyledSearch,
  StyledSearchInput,
  StyledIcon,
  StyledInputWrapper,
  StyledNavigation,
  StyledHider,
  StyledGenerationSwitcherContainer,
} from "./styles";
import { filterMenu } from "../../utils/menu";
import { GenerationContext } from "../../contexts/generationContext";
import { GENERATIONS_MAP, nonGenPaths } from "./constants";

const Sidebar: React.FC<ISidebar> = (props) => {
  const { generation: contextGen, updateGeneration } = useContext(
    GenerationContext
  );
  const { onCloseMenu, isOpen, headerUpScrolled } = props;
  const sidebar: RefObject<HTMLDivElement> = useRef(null);
  const { value, onChange, clear } = useInput("");
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const generation = pathname.split("/")[1];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filteredPagesConfig = useMemo(() => filterMenu(value), [value]);

  const handleClose = useCallback(() => {
    if (onCloseMenu) {
      onCloseMenu();
    }

    clear();
  }, [onCloseMenu, clear]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeGeneration = (v: any) => {
    const newPathname = pathname.split("/");
    if (GENERATIONS_MAP.includes(v) && !nonGenPaths.includes(newPathname[1])) {
      newPathname[1] = v;

      replace(newPathname.join("/"));
    }
    updateGeneration(v);
  };

  const icon = value.length ? (
    <StyledIcon onClick={clear}>
      <CloseLarge />
    </StyledIcon>
  ) : (
    <StyledIcon>
      <Search />
    </StyledIcon>
  );

  useClickAway(sidebar, handleClose);

  useEffect(() => {
    if (!isOpen) {
      clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <StyledSidebar
        ref={sidebar}
        $open={isOpen}
        $headerUpScrolled={headerUpScrolled}
      >
        <StyledLogoLink to="/">
          <StyledLogo>
            <Rostelecomlogo />
          </StyledLogo>
          <span>Дизайн-система Ростелекома</span>
        </StyledLogoLink>
        <StyledGenerationSwitcherContainer className="atomaro-dark-theme">
          <Select
            size="s"
            showLabel={false}
            placement="bottom"
            items={[
              { value: "1-е поколение", key: "gen1" },
              { value: "2-е поколение (Атомаро)", key: "gen2" },
            ]}
            value={nonGenPaths.includes(generation) ? contextGen : generation}
            onChange={(v) => {
              if (
                v &&
                v !== generation &&
                GENERATIONS_MAP.includes(v.toString())
              ) {
                handleChangeGeneration(v);
              }
            }}
            readOnly
            useInPortal={false}
          />
        </StyledGenerationSwitcherContainer>
        <StyledSearch>
          <StyledInputWrapper>
            <StyledSearchInput
              label="Поиск"
              onChange={onChange}
              value={value}
              defaultValue=""
              clearable
              icon={icon}
              color="primary1"
            />
          </StyledInputWrapper>
        </StyledSearch>
        <StyledNavigation>
          <SidebarAccordion
            pagesConfig={filteredPagesConfig}
            onCloseMenu={onCloseMenu}
          />
        </StyledNavigation>
      </StyledSidebar>
      {isOpen && <StyledHider $isSidebarFixed={headerUpScrolled} />}
    </>
  );
};

export default Sidebar;
