export const BREAKPOINTS = {
  mobile: 320,
  tablet: 768,
  tablet_xl: 1024,
  desktop: 1440,
  desktopXl: 1920,
};

export const MEDIA = {
  onlyMobile: `(max-width: ${BREAKPOINTS.tablet - 1}px)`,
  toTablet: `(max-width: ${BREAKPOINTS.tablet - 1}px)`,
  toTabletXl: `(max-width: ${BREAKPOINTS.tablet_xl - 1}px)`,
  toDesktop: `(max-width: ${BREAKPOINTS.desktop - 1}px)`,
  toDesktopXl: `(max-width: ${BREAKPOINTS.desktopXl - 1}px)`,
  fromTablet: `(min-width: ${BREAKPOINTS.tablet}px)`,
  fromTabletXl: `(min-width: ${BREAKPOINTS.tablet_xl}px)`,
  fromDesktopXs: `(min-width: ${BREAKPOINTS.tablet_xl + 1}px)`,
  fromDesktop: `(min-width: ${BREAKPOINTS.desktop}px)`,
  fromDesktopXl: `(min-width: ${BREAKPOINTS.desktopXl}px)`,
};

export enum SPACING_BREAKPOINTS {
  mobile_small = 320,
  mobile_large = 375,
  tablet_vertical_large = 768,
  desktop_small = 1280,
}

export const MAX_PAGE_CONTENT_WIDTH = 1024;
