import { createContext } from "react";
import { noop } from "../utils/function";

type TabContextType = {
  selectedTab: string;
  onSelectTab: (tabKey: string) => void;
};

const initialState: TabContextType = {
  selectedTab: "",
  onSelectTab: noop,
};

export default createContext(initialState);
