import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import { StyledHeaderType, StyledPageLayoutPropsType } from "./types";
import { StyledButton } from "../Button/FunctionButton/styles";
import { MEDIA } from "../../constants/media";
import {
  PAGE_HEADER_HEIGHT,
  PAGE_HEADER_MOBILE_HEIGHT,
} from "../../constants/theme";

const generateMobileOverflow = ({ $isMenuOpen }: StyledPageLayoutPropsType) =>
  $isMenuOpen
    ? css`
        overflow-y: hidden;
      `
    : css``;

const generateMobileHeader = ({ $isPageUpScrolled }: StyledHeaderType) =>
  $isPageUpScrolled
    ? css`
        position: sticky;
        top: 0;
        z-index: 10;
      `
    : css``;

export const StyledPageLayout = styled(StickyContainer)<
  StyledPageLayoutPropsType
>`
  overflow-x: hidden;
  overflow-y: auto;

  @media screen and ${MEDIA.toTabletXl} {
    ${generateMobileOverflow}
  }
`;

export const StyledHeader = styled.header<StyledHeaderType>`
  background: #1d1b1f;
  height: ${PAGE_HEADER_MOBILE_HEIGHT}px;
  width: 100%;
  display: grid;
  padding: 8px 16px;
  grid-template-areas: "button . . logo . . .";
  align-items: center;
  box-sizing: border-box;
  z-index: 5;

  ${generateMobileHeader};

  @media screen and ${MEDIA.fromTablet} {
    height: ${PAGE_HEADER_HEIGHT}px;
  }

  @media screen and ${MEDIA.fromDesktopXs} {
    display: none;
  }

  ${StyledButton} {
    background: transparent;
    padding: 0;
    height: 24px;
    grid-area: button;
    justify-self: baseline;
    cursor: pointer;
    & > svg {
      fill: #fff;
    }
  }
`;

export const StyledLogoLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  grid-area: logo;
  justify-self: center;
  text-decoration: none;
  & > span {
    width: 150px;
    margin-left: 15px;
    color: #fff;
    font-family: Rostelecom Basis;
    font-size: 19px;
    line-height: 110%;
    display: none;
    flex-shrink: 0;
    @media screen and ${MEDIA.fromTablet} {
      display: inline;
    }
  }
`;

export const StyledLogo = styled.div`
  > svg {
    width: 40px;
    height: 40px;
  }
`;

export const StyledWrapper = styled.div`
  width: 100%;
  display: grid;
  height: calc(100vh - ${PAGE_HEADER_MOBILE_HEIGHT}px);
  grid-template-areas: "content" "footer";
  grid-template-rows: 1fr;
  position: relative;

  @media screen and ${MEDIA.fromTablet} {
    height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
  }

  @media screen and ${MEDIA.fromDesktopXs} {
    height: 100vh;
    grid-template-areas:
      "sidebar content"
      "sidebar footer";
    grid-template-columns: 256px 1fr;
  }
`;

export const StyledContent = styled.div`
  background: #fff;
  width: 100%;
  grid-area: content;
  box-sizing: border-box;
  min-width: 0;
`;

export const StyledFooter = styled.footer`
  width: 100%;
  background: #1d1b1f;
  box-sizing: border-box;
  padding: 30px 20px;
  grid-area: footer;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  @media screen and ${MEDIA.fromTablet} {
    padding: 40px 40px;
  }
  @media screen and ${MEDIA.fromDesktop} {
    padding: 40px 120px;
  }

  @media screen and ${MEDIA.fromTabletXl} {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

export const StyledCopyright = styled.p`
  color: rgba(255, 255, 255, 0.4);
  margin: 0;
  font-family: Rostelecom Basis;
  font-size: 14px;
  line-height: 20px;

  @media screen and ${MEDIA.fromTablet} {
    font-size: 16px;
  }

  @media screen and ${MEDIA.fromDesktop} {
    font-size: 20px;
  }
`;

export const StyledFeedback = styled.a`
  display: flex;
  text-decoration: none;
  align-items: center;
  outline: 0;
`;

export const StyledMail = styled.span`
  position: relative;
  color: #fff;
  font-family: Rostelecom Basis;
  font-size: 14px;
  line-height: 20px;
  flex-shrink: 0;

  @media screen and ${MEDIA.fromTablet} {
    font-size: 16px;
  }

  @media screen and ${MEDIA.fromDesktop} {
    font-size: 20px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
    z-index: 1;
  }
`;
