import React from "react";
import { IFunctionButton } from "./types";
import { StyledButton } from "./styles";

const FunctionButton: React.FC<IFunctionButton> = (props) => {
  const { icon, onClick, children, className } = props;
  return (
    <StyledButton type="button" onClick={onClick} className={className}>
      {icon}
      {children}
    </StyledButton>
  );
};

export default FunctionButton;
