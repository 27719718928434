import React, { FC } from "react";
import { LINK_TYPES } from "../../../constants/link";
import { noop } from "../../../utils/function";
import { useLink } from "../../../hooks/useLink";
import { ILinkButton } from "./types";
import { StyledContainer } from "./styles";

const LinkButton: FC<ILinkButton> = ({
  link,
  children,
  linkType = LINK_TYPES.outer,
  className,
  title,
}) => {
  const handleRedirect = useLink(link, linkType);

  if (linkType === LINK_TYPES.inner) {
    return (
      <StyledContainer
        className={className}
        onClick={handleRedirect}
        onTouchStart={noop}
        role="button"
        tabIndex={0}
        title={title}
      >
        {children}
      </StyledContainer>
    );
  }
  return (
    <StyledContainer
      className={className}
      onTouchStart={noop}
      role="button"
      tabIndex={0}
      title={title}
      href={link}
      target="_blank"
      rel="noreferrer preconnect prefetch preload prerender"
    >
      {children}
    </StyledContainer>
  );
};
LinkButton.displayName = "LinkButton";

export default LinkButton;
