import React, {
  Children,
  cloneElement,
  ReactNode,
  useRef,
  useEffect,
  useState,
} from "react";
import { ITabsProps } from "./types";
import {
  StyledContainer,
  StyledScrollContainer,
  StyledTabSpacing,
} from "./styles";

/* TODO: Сделать автоскролл если пользователь переключается на таб,
 * который видно только частично
 *
 * поправить отрисовку высоты табов при первой загрузке
 */
const DEFAULT_TABS_HEIGHT = 79;
const Tabs: React.FC<ITabsProps> = ({
  className,
  children: propChildren,
  value,
  onChange,
}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [tabsHeight, setTabsHeight] = useState<number>(DEFAULT_TABS_HEIGHT);
  const children: ReactNode[] = [];

  Children.forEach(propChildren, (child, index) => {
    if (!React.isValidElement(child)) {
      return;
    }

    children.push(
      cloneElement(child, {
        selected: value === child.props.id,
        onSelect: onChange,
      })
    );

    if (index !== Children.toArray(propChildren).length - 1) {
      children.push(<StyledTabSpacing key={`${child.props.id + index}`} />);
    }
  });

  useEffect(() => {
    const scrollElement = scrollRef.current;

    // TODO: подумать над адекватной реализацией без костыля с setTimeout
    setTimeout(() => {
      if (typeof scrollElement?.clientHeight === "number") {
        setTabsHeight(scrollElement.clientHeight);
      }
    }, 300);
  }, []);

  useEffect(() => {
    const updateTabsHeight = () => {
      const newHeight = scrollRef.current?.clientHeight;

      if (newHeight && tabsHeight !== newHeight) {
        setTabsHeight(newHeight);
      }
    };

    window.addEventListener("resize", updateTabsHeight);

    return () => {
      window.removeEventListener("resize", updateTabsHeight);
    };
  }, [tabsHeight]);

  return (
    <StyledContainer height={tabsHeight} className={className}>
      <StyledScrollContainer ref={scrollRef}>{children}</StyledScrollContainer>
    </StyledContainer>
  );
};
Tabs.displayName = "Tabs";

export default Tabs;
