import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { LOADER_TYPES } from "../../constants/loader";
import { MEDIA } from "../../constants/media";
import { StyledContainerType, StyledLoaderType } from "./types";
import { SIDEBAR_WIDTH } from "../../constants/sidebar";

const generageBackground = ({
  type,
}: StyledContainerType): FlattenSimpleInterpolation =>
  type === LOADER_TYPES.dark
    ? css`
        background-color: #1d1b1f;
        color: #fff;
      `
    : css``;

const generageProgress = ({
  progress,
}: StyledLoaderType): FlattenSimpleInterpolation =>
  css`
    transform: translateX(calc(-100% + ${progress}%));
  `;

export const StyledContainer = styled.div<StyledContainerType>`
  height: 100vh;
  ${generageBackground}
`;

export const StyledHeader = styled.div`
  width: 100%;
  background-color: rgb(244, 244, 245);
  height: 210px;

  & + div {
    @media screen and ${MEDIA.fromDesktop} {
      padding: 64px 64px 64px ${SIDEBAR_WIDTH + 40}px;
    }
  }

  @media screen and ${MEDIA.fromTablet} {
    height: 172px;
  }

  @media screen and ${MEDIA.fromTabletXl} {
    height: 182px;
  }
`;

export const StyledLine = styled.div<StyledLoaderType>`
  position: fixed;
  width: 100%;
  height: 4px;
  background-color: #7700ff;
  top: 0;
  left: 0;
  z-index: 10;
  transform: translateX(-100%);
  transition: transform linear;
  ${generageProgress};
`;
