import { css, FlattenSimpleInterpolation } from "styled-components";
import { SCROLL_SIZE } from "../../constants/scroll";
import { StyledTabsRootContainerType, StyledTabsContainerType } from "./types";

export const generateTabsSticky = ({
  isSticky,
}: StyledTabsRootContainerType): FlattenSimpleInterpolation =>
  isSticky
    ? css`
        position: fixed;
        left: 0;
        top: 0;
        z-index: 3;
        background-color: rgb(244, 244, 245);
        width: ${isSticky ? `calc(100vw - ${SCROLL_SIZE}px)` : "auto"};
      `
    : css`
        position: static;
      `;

export const generateTabsContainerWidth = ({
  width,
  left,
}: StyledTabsContainerType): FlattenSimpleInterpolation =>
  css`
    width: ${Number.isNaN(width) ? "auto" : `${width}px`};
    left: ${Number.isNaN(left) ? "unset" : `${left}px`};
  `;
