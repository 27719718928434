import { SectionInfoType, SubsectionInfoType } from "../types/config";
import pagesConfig from "../constants/pagesConfig";

export const filterMenu = (pattern: string): SectionInfoType[] => {
  const strPattern = pattern.toLowerCase();
  let result: SectionInfoType[] = [];

  if (!strPattern) {
    result = pagesConfig;
  } else {
    pagesConfig.forEach((sectionInfo) => {
      const { subsectionsInfo, name: sectionName } = sectionInfo;

      if (
        !subsectionsInfo.length &&
        sectionName.toLowerCase().includes(strPattern)
      ) {
        result.push({ ...sectionInfo, sectionClassName: "active" });
        return;
      }

      const resultSection: SectionInfoType = {
        ...sectionInfo,
        sectionClassName: "active",
        subsectionsInfo: [],
      };

      subsectionsInfo.forEach((subsection) => {
        const { name: subsectionName, pagesInfo } = subsection;

        const resultSubsection: SubsectionInfoType = {
          ...subsection,
          pagesInfo: [],
        };

        if (subsectionName.toLowerCase().includes(strPattern)) {
          resultSection.subsectionsInfo.push(subsection);
          return;
        }

        pagesInfo.forEach((pageInfo) => {
          const { name: pageName } = pageInfo;

          if (pageName.toLowerCase().includes(strPattern)) {
            resultSubsection.pagesInfo.push(pageInfo);
          }
        });

        if (resultSubsection.pagesInfo.length) {
          resultSection.subsectionsInfo.push(resultSubsection);
        }
      });

      if (resultSection.subsectionsInfo.length) {
        result.push(resultSection);
      }
    });
  }

  return result;
};
