import React, { FC, useState, useCallback } from "react";
import { Share } from "@design-system-rt/rtk-ui-kit";
import { copyToClipboard } from "../../../utils/function";
import Divider from "../../Divider/Divider";
import {
  StyledRootContainer,
  StyledLikesContainer,
  StyledBtnsContainer,
  StyledVerticalDividerContainer,
  StyledLikeBtn,
  StyledBtnSpacing,
  StyledBtnTypography,
  StyledTextTypography,
  StyledLike,
  StyledDislike,
  StyledShareBtn,
  StyledShareIcon,
  StyledMaterialTextTypography,
} from "./styles";

enum LIKES_BTN_KEY {
  yes = "yes",
  no = "no",
}

const FooterLikes: FC = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [selectedBtn, setselectedBtn] = useState("");

  const handleCopyLink = useCallback(() => {
    copyToClipboard(window.location.href);
    setIsCopied(true);
  }, []);

  const handleSelectYes = useCallback(() => {
    setselectedBtn(LIKES_BTN_KEY.yes);
  }, []);

  const handleSelectNo = useCallback(() => {
    setselectedBtn(LIKES_BTN_KEY.no);
  }, []);

  const shareText = isCopied ? "Скопировано" : "Поделиться";

  return (
    <StyledRootContainer>
      <StyledLikesContainer>
        <StyledMaterialTextTypography variant="accentM">
          Этот материал был вам полезен?
        </StyledMaterialTextTypography>
        <StyledBtnsContainer>
          <StyledLikeBtn
            active={selectedBtn === LIKES_BTN_KEY.yes}
            onClick={handleSelectYes}
          >
            <StyledBtnTypography variant="accentM" color="primary1">
              Да
            </StyledBtnTypography>
            <StyledLike />
          </StyledLikeBtn>
          <StyledBtnSpacing />
          <StyledLikeBtn
            active={selectedBtn === LIKES_BTN_KEY.no}
            onClick={handleSelectNo}
          >
            <StyledBtnTypography variant="accentM" color="primary1">
              Нет
            </StyledBtnTypography>
            <StyledDislike />
          </StyledLikeBtn>
        </StyledBtnsContainer>
      </StyledLikesContainer>
      <StyledVerticalDividerContainer>
        <Divider type="vertical" />
      </StyledVerticalDividerContainer>
      <StyledShareBtn onClick={handleCopyLink}>
        <StyledTextTypography variant="accentM">
          {shareText}
        </StyledTextTypography>
        <StyledShareIcon>
          <Share />
        </StyledShareIcon>
      </StyledShareBtn>
    </StyledRootContainer>
  );
};
FooterLikes.displayName = "ContentLayoutFooterLikes";

export default FooterLikes;
