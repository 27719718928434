import React from "react";
import { CloseLarge, Menu } from "@design-system-rt/rtk-ui-kit";
import { StyledHeader, StyledLogo, StyledLogoLink } from "./styles";
import FunctionButton from "../Button/FunctionButton/FunctionButton";
import { IHeader } from "./types";
import Rostelecomlogo from "../Icons/Rostelecomlogo";

const Header: React.FC<IHeader> = (props) => {
  const { menuOpen, toggleMenu, upScrolled } = props;
  const icon = menuOpen ? <CloseLarge /> : <Menu />;
  return (
    <StyledHeader $isPageUpScrolled={upScrolled}>
      <FunctionButton icon={icon} onClick={toggleMenu} />
      <StyledLogoLink to="/">
        <StyledLogo>
          <Rostelecomlogo />
        </StyledLogo>
        <span>Дизайн-система Ростелекома</span>
      </StyledLogoLink>
    </StyledHeader>
  );
};

export default Header;
