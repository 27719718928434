import React, { FC } from "react";
import { IContentProps } from "./types";
import { StyledContainer } from "./styles";

const Content: FC<IContentProps> = ({ children, ...restProps }) => {
  return <StyledContainer {...restProps}>{children}</StyledContainer>;
};

Content.displayName = "Content";

export default Content;
