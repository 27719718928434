import React from "react";

export enum GENERATIONS_MAP {
  gen1 = "gen1",
  gen2 = "gen2",
}

export const GENERATIONS_ARR = ["gen1", "gen2"];

export type TGenerationsMap = keyof typeof GENERATIONS_MAP;

export interface IGenerationContext {
  generation: TGenerationsMap;
  updateGeneration: (value: TGenerationsMap) => void;
}

export const GenerationContext = React.createContext<IGenerationContext>({
  generation: GENERATIONS_MAP.gen2,
  updateGeneration: () => {},
});

const GenerationContextProvider = GenerationContext.Provider;
export default GenerationContextProvider;
