import styled from "styled-components";
import { MAX_PAGE_CONTENT_WIDTH, MEDIA } from "../../constants/media";
import Content from "../Content/Content";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StyledContent = styled(Content)`
  display: flex;
  flex: auto;
  max-width: ${MAX_PAGE_CONTENT_WIDTH}px;
  min-height: 45px;
  width: 100%;
  padding: 0 40px;

  @media screen and ${MEDIA.toTablet} {
    padding: 0 20px;
  }
`;
