import React from "react";

const Rostelecomlogo: React.FC = () => (
  <svg
    width="25"
    height="40"
    viewBox="0 0 25 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.248 40H3.86727C3.44457 40 3.03344 39.858 2.6966 39.5957C1.84397 38.9321 1.67691 37.6848 2.32348 36.8099L8.60844 30.4297C8.60859 30.4297 11.8218 33.6198 18.248 40Z"
      fill="#FF4F12"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 36.8V12.2528C0.75 10.8072 1.30322 9.4176 2.29399 8.3744L10.25 0L24.5 14.4L2.53204 36.6128C2.16257 36.9848 1.95484 37.4904 1.95484 38.0176C1.95484 39.1128 2.83319 40 3.91667 40C2.1678 40 0.75 38.5672 0.75 36.8Z"
      fill="#7700FF"
    />
  </svg>
);

export default Rostelecomlogo;
