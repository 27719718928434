import { css, FlattenSimpleInterpolation } from "styled-components";
import { MEDIA } from "../../../constants/media";

export const generatePadding = (): FlattenSimpleInterpolation => css`
  padding: 0 40px;

  @media screen and ${MEDIA.toTablet} {
    padding: 0 20px;
  }
`;

export const generateLeftMargin = (): FlattenSimpleInterpolation => css`
  margin-left: 40px;

  @media screen and ${MEDIA.toTablet} {
    margin-left: 20px;
  }
`;

export const generateRightGutter = (): FlattenSimpleInterpolation => css`
  &:after {
    content: "";
    width: 40px;
    height: 1px;
    position: absolute;
  }

  @media screen and ${MEDIA.toTablet} {
    &:after {
      width: 20px;
    }
  }
`;
