import styled, { css } from "styled-components";
import { MEDIA } from "../../constants/media";
import { TabPropsType } from "./types";

const generateBottomLine = ({ theme, selected }: TabPropsType) => css`
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${selected ? "100%" : "0%"};
    height: 2px;
    transition: width 0.2s ease, background-color 0.2s ease;
    background-color: ${theme.baseColors.primary1(1)};
    z-index: 2;
  }
`;

const generateColor = ({ theme, selected }: TabPropsType) => css`
  color: ${selected ? theme.baseColors.primary1(1) : theme.transparent[100]};
`;

const generateActiveColor = ({ theme }: TabPropsType) => css`
  color: ${theme.baseColors.primary1(1)};
`;

export const StyledTab = styled.p<TabPropsType>`
  position: relative;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 20px 0 18px;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Rostelecom Basis";
  font-style: normal;
  font-feature-settings: "tnum", "lnum";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  ${generateColor};
  ${generateBottomLine};

  @media not all and (hover: none) {
    &:hover {
      ${generateActiveColor};
    }
  }

  &:active {
    ${generateActiveColor};
  }

  @media screen and ${MEDIA.fromTablet} {
    font-size: 16px;
    line-height: 24px;
  }

  @media screen and ${MEDIA.toTablet} {
    padding-top: 12px;
    padding-bottom: 10px;
  }
`;
