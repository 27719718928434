import React, { FC, useEffect, useState, useCallback, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import TabsContext from "../../contexts/tabsContext";
import pageLayoutContext from "../../contexts/pageLayoutContext";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { IPageContentLayout } from "./types";
import { StyledContainer, StyledContent } from "./styles";

const PageContentLayout: FC<IPageContentLayout> = ({
  children,
  storybookLink,
  storybookVueLink,
  figmaLink,
  tabs,
  title,
  path,
  additionalInfo,
  withoutSubsection,
}) => {
  const { pageLayoutRef } = useContext(pageLayoutContext);
  const { replace } = useHistory();
  const { pathname, hash = "", state } = useLocation();
  const [selectedTab, setSelectedTab] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const generationFromPathname: any = pathname.split("/")[1];

  const handleSelectTab = useCallback(
    (tabKey: string, tabHash?: string) => {
      setSelectedTab(tabKey);
      replace(`${path}/${tabKey}${tabHash || ""}`, state);
    },
    [path, state, replace]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const linkTransform = (link: any): string | undefined => {
    return link?.[generationFromPathname];
  };

  useEffect(() => {
    if (pageLayoutRef?.current) {
      const layout = pageLayoutRef.current;

      // TODO: подумать над адекватной реализацией без костыля с setTimeout
      setTimeout(() => {
        if (hash) {
          const element = document.getElementById(hash.replace("#", ""));

          element?.scrollIntoView({
            behavior: "smooth",
          });
        } else {
          layout.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      }, 300);
    }
  }, [pageLayoutRef, hash, selectedTab]);

  useEffect(() => {
    if (!tabs?.length) {
      return;
    }

    handleSelectTab(tabs[0].key, hash);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let tabKey = "";

    if (withoutSubsection) {
      const [, , currentTab] = pathname.split("/");
      tabKey = currentTab;
    } else {
      const [, , , , currentTab] = pathname.split("/");
      tabKey = currentTab;
    }

    if (tabKey && tabKey !== selectedTab) {
      handleSelectTab(tabKey, hash);
    }

    // if (!tabKey && tabs?.length) {
    //   handleSelectTab(tabs[0].key, hash);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const tabsContextValue = {
    selectedTab,
    onSelectTab: handleSelectTab,
  };

  return (
    <TabsContext.Provider value={tabsContextValue}>
      <StyledContainer>
        <Header
          storybookLink={linkTransform(storybookLink)}
          storybookVueLink={linkTransform(storybookVueLink)}
          figmaLink={linkTransform(figmaLink)}
          tabs={tabs}
          title={title}
          additionalInfo={additionalInfo}
        />
        <StyledContent spacingTop="xxxl" spacingBottom="xxxl">
          {children}
        </StyledContent>
        <Footer />
      </StyledContainer>
    </TabsContext.Provider>
  );
};
PageContentLayout.displayName = "ContentLayout";

export default PageContentLayout;
