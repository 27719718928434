import React, { useCallback, useRef } from "react";
import { noop } from "../../utils/function";
import { ITabProps } from "./types";
import { StyledTab } from "./styles";

const Tab: React.FC<ITabProps> = ({
  children,
  id,
  selected,
  onSelect = noop,
}) => {
  const tabRef = useRef<HTMLDivElement | null>(null);

  const scrollIntoTab = () => {
    if (
      (tabRef.current &&
        tabRef.current?.getBoundingClientRect().right > window.innerWidth) ||
      (tabRef.current?.getBoundingClientRect().left &&
        tabRef.current?.getBoundingClientRect().left < 0)
    ) {
      tabRef.current?.scrollIntoView({
        inline: "center",
        block: "nearest",
        behavior: "smooth",
      });
    }
  };
  const handleSelect = useCallback(() => {
    onSelect(id);
    scrollIntoTab();
  }, [id, onSelect]);

  return (
    <StyledTab selected={selected} ref={tabRef} onClick={handleSelect}>
      {children}
    </StyledTab>
  );
};
Tab.displayName = "Tab";

export default Tab;
