import styled from "styled-components";
import { MEDIA } from "../../../constants/media";
import LinkButton from "../../Button/LinkButton/LinkButton";

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & > a:not(:last-child) {
    margin-right: 12px;
  }

  @media screen and ${MEDIA.toTablet} {
    margin-top: 24px;
  }
`;
export const StyledLinkBtn = styled(LinkButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 63px;
  @media not all and (hover: none) {
    &:hover {
      background-color: ${({ theme }) => theme.transparent[10]};
    }
  }

  &:active {
    background: rgba(16, 24, 40, 0.15);
  }
`;
