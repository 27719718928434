import { css, FlattenSimpleInterpolation } from "styled-components";
import { SPACING_BREAKPOINTS } from "../../constants/media";
import { SPACING_TYPES, themeSpacing } from "../../constants/spacing";

import { ContentContainerPropsType } from "./types";

export const generateSpacing = ({
  spacing,
}: ContentContainerPropsType): FlattenSimpleInterpolation =>
  spacing
    ? css`
        @media screen and (min-width: ${SPACING_BREAKPOINTS.mobile_small}px) {
          & {
            padding: ${themeSpacing.spacingFull[
              SPACING_BREAKPOINTS.mobile_small
            ][spacing]}px;
          }
        }
        @media screen and (min-width: ${SPACING_BREAKPOINTS.mobile_large}px) {
          & {
            padding: ${themeSpacing.spacingFull[
              SPACING_BREAKPOINTS.mobile_large
            ][spacing]}px;
          }
        }
        @media screen and (min-width: ${SPACING_BREAKPOINTS.tablet_vertical_large}px) {
          & {
            padding: ${themeSpacing.spacingFull[
              SPACING_BREAKPOINTS.tablet_vertical_large
            ][spacing]}px;
          }
        }
      `
    : css``;

export const generateTopSpacing = ({
  spacingTop,
  spacingTopType = SPACING_TYPES.margin,
}: ContentContainerPropsType): FlattenSimpleInterpolation => {
  return spacingTop
    ? css`
        @media screen and (min-width: ${SPACING_BREAKPOINTS.mobile_small}px) {
          & {
            ${spacingTopType}-top: ${themeSpacing.spacingVertical[
              SPACING_BREAKPOINTS.mobile_small
            ][spacingTop]}px;
          }
        }
        @media screen and (min-width: ${SPACING_BREAKPOINTS.mobile_large}px) {
          & {
            ${spacingTopType}-top: ${themeSpacing.spacingVertical[
              SPACING_BREAKPOINTS.mobile_large
            ][spacingTop]}px;
          }
        }
        @media screen and (min-width: ${SPACING_BREAKPOINTS.desktop_small}px) {
          & {
            ${spacingTopType}-top: ${themeSpacing.spacingVertical[
              SPACING_BREAKPOINTS.desktop_small
            ][spacingTop]}px;
          }
        }
      `
    : css``;
};

export const generateBottomSpacing = ({
  spacingBottom,
}: ContentContainerPropsType): FlattenSimpleInterpolation =>
  spacingBottom
    ? css`
        @media screen and (min-width: ${SPACING_BREAKPOINTS.mobile_small}px) {
          & {
            margin-bottom: ${themeSpacing.spacingVertical[
              SPACING_BREAKPOINTS.mobile_small
            ][spacingBottom]}px;
          }
        }
        @media screen and (min-width: ${SPACING_BREAKPOINTS.mobile_large}px) {
          & {
            margin-bottom: ${themeSpacing.spacingVertical[
              SPACING_BREAKPOINTS.mobile_large
            ][spacingBottom]}px;
          }
        }
        @media screen and (min-width: ${SPACING_BREAKPOINTS.desktop_small}px) {
          & {
            margin-bottom: ${themeSpacing.spacingVertical[
              SPACING_BREAKPOINTS.desktop_small
            ][spacingBottom]}px;
          }
        }
      `
    : css``;
