import styled from "styled-components";
import { StyledTabsRootContainerType, StyledTabsContainerType } from "./types";
import {
  generateTabsSticky,
  generateTabsContainerWidth,
} from "./styledGenerators";

// TODO: Добавлено из-за особенности библиотеки
export const StyledStickyContainer = styled.div`
  & > div > div:first-child {
    padding: 0 !important;
  }
`;

export const StyledTabsRootContainer = styled.div<StyledTabsRootContainerType>`
  ${generateTabsSticky}
`;

export const StyledTabsContainer = styled.div<StyledTabsContainerType>`
  position: relative;
  ${generateTabsContainerWidth};
  width: 100%;
`;
