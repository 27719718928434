import styled from "styled-components";
import { ContentContainerPropsType } from "./types";
import {
  generateSpacing,
  generateTopSpacing,
  generateBottomSpacing,
} from "./styledGenerators";

export const StyledContainer = styled.div<ContentContainerPropsType>`
  ${generateSpacing}
  ${generateTopSpacing}
  ${generateBottomSpacing}
`;
