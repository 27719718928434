import { css, FlattenSimpleInterpolation } from "styled-components";
import { StyledSidebarType, StyledValueType, StyledHiderType } from "./types";
import { SIDEBAR_WIDTH } from "../../constants/sidebar";
import { MEDIA } from "../../constants/media";
import {
  PAGE_HEADER_MOBILE_HEIGHT,
  PAGE_HEADER_HEIGHT,
} from "../../constants/theme";

export const generateLabelColor = ({
  value,
}: StyledValueType): FlattenSimpleInterpolation =>
  value
    ? css`
        color: rgba(255, 255, 255, 0.4);
      `
    : css`
        color: rgba(255, 255, 255, 0.6);
      `;

export const generateSidebarToggling = ({
  $open,
}: StyledSidebarType): FlattenSimpleInterpolation =>
  $open
    ? css`
        transform: translateX(0px);
      `
    : css`
        transform: translateX(${-SIDEBAR_WIDTH - 10}px);
      `;

export const generateFixedSidebar = ({
  $headerUpScrolled,
}: StyledSidebarType): FlattenSimpleInterpolation =>
  $headerUpScrolled
    ? css`
        position: fixed;
        top: ${PAGE_HEADER_MOBILE_HEIGHT}px;
        padding-bottom: ${PAGE_HEADER_MOBILE_HEIGHT}px;
        @media screen and ${MEDIA.fromTablet} {
          top: ${PAGE_HEADER_HEIGHT}px;
          padding-bottom: ${PAGE_HEADER_HEIGHT}px;
        }
      `
    : css``;

export const generateFixedHider = ({
  $isSidebarFixed,
}: StyledHiderType): FlattenSimpleInterpolation =>
  $isSidebarFixed
    ? css`
        position: fixed;
      `
    : css``;
