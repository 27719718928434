import { getSpacing } from "../utils/spacing";
import { SPACING_BREAKPOINTS } from "./media";

export enum SPACING_FULL_KEYS_LIST {
  s = "s",
  m = "m",
  l = "l",
  xl = "xl",
}

export enum SPACING_VERTICAL_KEYS_LIST {
  xs = "xs",
  s = "s",
  m = "m",
  l = "l",
  xl = "xl",
  xxl = "xxl",
  xxxl = "xxxl",
}

export const ADAPTIVE_SPACING_FULL = {
  [SPACING_BREAKPOINTS.mobile_small]: {
    [SPACING_FULL_KEYS_LIST.s]: getSpacing(2),
    [SPACING_FULL_KEYS_LIST.m]: getSpacing(3),
    [SPACING_FULL_KEYS_LIST.l]: getSpacing(5),
    [SPACING_FULL_KEYS_LIST.xl]: getSpacing(5),
  },
  [SPACING_BREAKPOINTS.mobile_large]: {
    [SPACING_FULL_KEYS_LIST.s]: getSpacing(3),
    [SPACING_FULL_KEYS_LIST.m]: getSpacing(4),
    [SPACING_FULL_KEYS_LIST.l]: getSpacing(6),
    [SPACING_FULL_KEYS_LIST.xl]: getSpacing(10),
  },
  [SPACING_BREAKPOINTS.tablet_vertical_large]: {
    [SPACING_FULL_KEYS_LIST.s]: getSpacing(3),
    [SPACING_FULL_KEYS_LIST.m]: getSpacing(4),
    [SPACING_FULL_KEYS_LIST.l]: getSpacing(8),
    [SPACING_FULL_KEYS_LIST.xl]: getSpacing(16),
  },
};

export const ADAPTIVE_SPACING_VERTICAL = {
  [SPACING_BREAKPOINTS.mobile_small]: {
    [SPACING_VERTICAL_KEYS_LIST.xs]: getSpacing(1),
    [SPACING_VERTICAL_KEYS_LIST.s]: getSpacing(2),
    [SPACING_VERTICAL_KEYS_LIST.m]: getSpacing(3),
    [SPACING_VERTICAL_KEYS_LIST.l]: getSpacing(5),
    [SPACING_VERTICAL_KEYS_LIST.xl]: getSpacing(6),
    [SPACING_VERTICAL_KEYS_LIST.xxl]: getSpacing(8),
    [SPACING_VERTICAL_KEYS_LIST.xxxl]: getSpacing(10),
  },
  [SPACING_BREAKPOINTS.mobile_large]: {
    [SPACING_VERTICAL_KEYS_LIST.xs]: getSpacing(2),
    [SPACING_VERTICAL_KEYS_LIST.s]: getSpacing(3),
    [SPACING_VERTICAL_KEYS_LIST.m]: getSpacing(4),
    [SPACING_VERTICAL_KEYS_LIST.l]: getSpacing(6),
    [SPACING_VERTICAL_KEYS_LIST.xl]: getSpacing(8),
    [SPACING_VERTICAL_KEYS_LIST.xxl]: getSpacing(10),
    [SPACING_VERTICAL_KEYS_LIST.xxxl]: getSpacing(13),
  },
  [SPACING_BREAKPOINTS.desktop_small]: {
    [SPACING_VERTICAL_KEYS_LIST.xs]: getSpacing(2),
    [SPACING_VERTICAL_KEYS_LIST.s]: getSpacing(4),
    [SPACING_VERTICAL_KEYS_LIST.m]: getSpacing(5),
    [SPACING_VERTICAL_KEYS_LIST.l]: getSpacing(8),
    [SPACING_VERTICAL_KEYS_LIST.xl]: getSpacing(10),
    [SPACING_VERTICAL_KEYS_LIST.xxl]: getSpacing(12),
    [SPACING_VERTICAL_KEYS_LIST.xxxl]: getSpacing(14),
  },
};

export const themeSpacing = {
  spacingFull: ADAPTIVE_SPACING_FULL,
  spacingVertical: ADAPTIVE_SPACING_VERTICAL,
};

export enum SPACING_TYPES {
  padding = "padding",
  margin = "margin",
}
