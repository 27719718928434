import React, { FC, memo, useContext, useCallback } from "react";
import { ArrowLeft, Sticker, Typography } from "@design-system-rt/rtk-ui-kit";
import { useHistory, useLocation } from "react-router-dom";
import { TabInfoType } from "../../../types/tabs";
import tabsContext from "../../../contexts/tabsContext";
import Tab from "../../Tab/Tab";
import StickyTabsContainer from "../../StickyTabsContainer/StickyTabsContainer";
import LinkBtnsGroup from "../LinkBtnsGroup/LinkBtnsGroup";
import {
  StyledRootContainer,
  StyledContentContainer,
  StyledHeaderContent,
  StyledHeaderContainer,
  StyledBackBtn,
  StyledTabs,
} from "./styles";
import { IHeader } from "./types";
import { GenerationContext } from "../../../contexts/generationContext";

const Header: FC<IHeader> = memo(
  ({
    storybookLink,
    storybookVueLink,
    figmaLink,
    tabs,
    title,
    additionalInfo = () => {},
  }) => {
    const { selectedTab, onSelectTab } = useContext(tabsContext);
    const { pathname } = useLocation();
    const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const generationFromPathname: any = pathname.split("/")[1];
    const { generation: contextGen } = useContext(GenerationContext);

    const innerPage =
      pathname.includes("release-") || pathname.includes("announcement-");

    const handleBack = useCallback(() => {
      history.push("/history/all");
    }, [history]);

    const tabsList =
      !innerPage && tabs?.length ? (
        <StickyTabsContainer>
          <StyledTabs value={selectedTab} onChange={onSelectTab}>
            {tabs.map(({ key, label, gen }: TabInfoType) => {
              if (gen && !gen.includes(generationFromPathname)) {
                return null;
              }
              return (
                <Tab key={key} id={key}>
                  {label}
                </Tab>
              );
            })}
          </StyledTabs>
        </StickyTabsContainer>
      ) : null;

    const backBtn = innerPage ? (
      <StickyTabsContainer>
        <StyledBackBtn onClick={handleBack}>
          <ArrowLeft />
          <Typography variant="accentS" color="main">
            Все записи
          </Typography>
        </StyledBackBtn>
      </StickyTabsContainer>
    ) : null;

    const betaTitle =
      title === "ARTi" || title === "TableGrid" || title === "Tree";

    return (
      <StyledRootContainer>
        <StyledContentContainer>
          <StyledHeaderContainer spacingBottom="m">
            <StyledHeaderContent spacingTop="xxxl">
              <Typography variant="mega">{title}</Typography>
              {betaTitle && (
                <Sticker
                  style={{ marginLeft: 6 }}
                  shape="circular"
                  type="secondary2"
                >
                  {" "}
                  beta
                </Sticker>
              )}
            </StyledHeaderContent>
            <LinkBtnsGroup
              storybookLink={storybookLink}
              storybookVueLink={storybookVueLink}
              figmaLink={figmaLink}
            />
            {additionalInfo(contextGen)}
          </StyledHeaderContainer>
          {backBtn}
          {tabsList}
        </StyledContentContainer>
      </StyledRootContainer>
    );
  }
);
Header.displayName = "ContentLayoutHeader";

export default Header;
