import React from "react";

const LeftLongArrow: React.FC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="1">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.01 39.9995L69.999 39.9995L69.999 38.9995L12.0088 38.9995L23.0778 28.7312L22.3977 27.998L10.3952 39.1324L10 39.4989L10.3952 39.8655L22.3977 50.9998L23.0778 50.2667L12.01 39.9995Z"
      />
    </g>
  </svg>
);

export default LeftLongArrow;
