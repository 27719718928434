import React, { FC } from "react";
import Content from "../Content/Content";
import { DarkLoaderPropsType } from "./types";

const DarkLoader: FC<DarkLoaderPropsType> = ({ text }) => {
  return <Content spacing="xl">{text}</Content>;
};
DarkLoader.displayName = "DarkLoader";

export default DarkLoader;
