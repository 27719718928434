import pagesConfig from "../constants/pagesConfig";
import { RouteInfoType } from "../types/routes";

export const getPagesRouteslist = (gen: string): RouteInfoType[] => {
  const result: RouteInfoType[] = [];
  pagesConfig.forEach(
    ({ name: sectionName, path: sectionPath, subsectionsInfo, generation }) => {
      if (!subsectionsInfo.length) {
        result.push({
          sectionName: " ",
          pageName: sectionName,
          path: sectionPath,
          generation,
        });

        return;
      }
      subsectionsInfo.forEach(({ pagesInfo }) => {
        if (!pagesInfo.length) {
          return;
        }

        pagesInfo.forEach(
          ({ path: pagePath, name: pageName, active, generation }) => {
            if (!active) {
              return;
            }

            result.push({
              sectionName,
              pageName,
              path: `${sectionPath}${pagePath}`,
              generation,
            });
          }
        );
      });
    }
  );

  return result.filter(
    ({ generation }) => generation?.length === 0 || generation?.includes(gen)
  );
};

export const getNearestRoutesInfo = (
  path: string,
  generation: string
): {
  prevRouteInfo: RouteInfoType | null;
  nextRouteInfo: RouteInfoType | null;
} => {
  const pagesRouteslist = getPagesRouteslist(generation);

  const currentRouteIndex = pagesRouteslist.findIndex((routeInfo) => {
    if (routeInfo.generation?.includes(generation)) {
      return (
        routeInfo.path === `/${path.split("/").slice(2, 4).join("/")}` ||
        routeInfo.path === path.split("/").slice(2, 3).join("/")
      );
    }
    return (
      routeInfo.path === path.split("/").slice(0, 3).join("/") ||
      routeInfo.path === path.split("/").slice(0, 2).join("/")
    );
  });

  const prevRouteInfo =
    currentRouteIndex > 0 ? pagesRouteslist[currentRouteIndex - 1] : null;
  const nextRouteInfo =
    currentRouteIndex < pagesRouteslist.length - 1
      ? pagesRouteslist[currentRouteIndex + 1]
      : null;

  return {
    prevRouteInfo,
    nextRouteInfo,
  };
};
