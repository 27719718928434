import React, {
  MouseEvent,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { StyledPageLayout, StyledWrapper, StyledContent } from "./styles";
import { useVisibleState } from "../../hooks/useVisibleState";
import PageLayoutContext from "../../contexts/pageLayoutContext";
import Sidebar from "../Sidebar/Sidebar";
import Header from "./Header";
import Footer from "./Footer";

const PageLayout: React.FC = ({ children }) => {
  const pageLayoutRef = useRef<HTMLDivElement | null>(null);
  const [upScrolled, setUpScrolled] = useState(false);
  const lastPoint = useRef(0);
  const { show, hide, toggleShow } = useVisibleState(false);
  const pageLayoutContextValue = {
    pageLayoutRef,
  };

  const setLayoutRef = (element: HTMLDivElement) => {
    if (element) {
      pageLayoutRef.current = element.firstChild as HTMLDivElement;
    }
  };

  const toggleMenu = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    event.stopPropagation();
    toggleShow();
  };

  const handleScroll = useCallback(() => {
    if (window.innerWidth < 1025) {
      const currentPoint = pageLayoutRef.current?.scrollTop;

      const isUpScrolled = Boolean(
        lastPoint.current && currentPoint && currentPoint < lastPoint.current
      );
      setUpScrolled(isUpScrolled);
      if (typeof currentPoint === "number") {
        lastPoint.current = currentPoint;
      }
    }
  }, []);

  useEffect(() => {
    if (pageLayoutRef.current) {
      pageLayoutRef.current?.addEventListener("scroll", handleScroll);
    }
    return () => {
      pageLayoutRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div ref={setLayoutRef}>
      <StyledPageLayout $isMenuOpen={show} id="container">
        <Header
          toggleMenu={toggleMenu}
          menuOpen={show}
          upScrolled={upScrolled}
        />
        <StyledWrapper>
          <Sidebar
            onCloseMenu={hide}
            isOpen={show}
            headerUpScrolled={upScrolled}
          />
          <PageLayoutContext.Provider value={pageLayoutContextValue}>
            <StyledContent>{children}</StyledContent>
          </PageLayoutContext.Provider>
          <Footer />
        </StyledWrapper>
      </StyledPageLayout>
    </div>
  );
};

export default PageLayout;
