import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Input } from "@design-system-rt/rtk-ui-kit";
import { MEDIA } from "../../constants/media";
import { SIDEBAR_WIDTH } from "../../constants/sidebar";
import { StyledLogoLink } from "../PageLayout/styles";
import { StyledButton } from "../Button/FunctionButton/styles";
import { StyledSidebarType, StyledHiderType } from "./types";
import {
  generateLabelColor,
  generateSidebarToggling,
  generateFixedSidebar,
  generateFixedHider,
} from "./styledGenerators";

export const StyledSidebar = styled.div<StyledSidebarType>`
  width: ${SIDEBAR_WIDTH}px;
  background: #1d1b1f;
  grid-area: sidebar;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  transition: transform 200ms linear;
  position: absolute;
  height: 100%;
  z-index: 10;
  ${generateSidebarToggling};
  ${generateFixedSidebar};
  @media screen and ${MEDIA.fromDesktopXs} {
    display: flex;
    transform: none;
    position: fixed;
  }
  ${StyledLogoLink} {
    display: none;
    @media screen and ${MEDIA.fromDesktopXs} {
      display: flex;
      padding: 38px 48px 0px 12px;
    }
  }
`;

export const StyledHider = styled.div<StyledHiderType>`
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(16, 24, 40, 0.5);
  position: absolute;

  ${generateFixedHider};

  @media screen and ${MEDIA.fromDesktopXs} {
    display: none;
  }
`;

export const StyledSearch = styled.div`
  padding: 32px 20px 0 20px;
`;

export const StyledInputWrapper = styled.div`
  position: relative;
`;

export const StyledSearchInput = styled(Input)`
  width: 216px;
  height: auto;

  & button {
    display: flex;
    justify-content: center;
  }

  & > div {
    & > input {
      height: auto;
      color: rgba(255, 255, 255, 0.9);
      caret-color: rgba(255, 255, 255, 0.9);
    }

    & > input:focus {
      & ~ label {
        color: #9466ff;
      }
    }

    & > label {
      ${generateLabelColor}
    }

    &::after {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`;

export const StyledIcon = styled.span`
  & > svg path {
    fill: rgba(255, 255, 255, 0.4);
  }

  @media not all and (hover: none) {
    &:hover {
      & > svg path {
        fill: rgba(255, 255, 255, 0.4);
      }
    }
  }
`;

export const StyledNavigation = styled.nav`
  overflow-x: hidden;
  overflow-y: auto;
  padding: 44px 0;
  width: ${SIDEBAR_WIDTH}px;

  @media not all and (hover: none) {
    &:hover,
    &:focus {
      visibility: visible;
    }
  }

  @media screen and ${MEDIA.fromDesktopXs} {
    visibility: hidden;
  }
`;

export const StyledAccordion = styled.div`
  display: flex;
  flex-direction: column;
  visibility: visible;
`;

export const StyledAccordionSection = styled.div``;

export const StyledMenuList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 40px;
  display: none;
`;

export const StyledMenuItem = styled.li``;

export const StyledMenuLink = styled(NavLink)`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  font-family: Rostelecom Basis;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0 8px 15px;
  display: block;
  &.active {
    color: #fff;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #9466ff;
      top: 15px;
      left: 0;
    }
  }

  @media not all and (hover: none) {
    &:hover {
      color: #fff;
    }
  }
`;

export const InactiveLink = styled(StyledMenuLink)`
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
`;

export const StyledMenuSection = styled.p`
  font-family: Rostelecom Basis;
  font-size: 12px;
  line-height: 16px;
  margin: 10px 0 6px;
  color: rgba(255, 255, 255, 0.4);
  &:first-child {
    margin: 20px 0 6px;
  }
`;

export const StyledAccordionButton = styled(StyledButton)`
  background: transparent;
  color: rgba(255, 255, 255, 0.9);
  font-family: Rostelecom Basis;
  font-size: 16px;
  line-height: 20px;
  padding: 14px 40px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  &:hover,
  &.active {
    background: rgba(255, 255, 255, 0.06);
  }
  &.active {
    & + ${StyledMenuList} {
      display: block;
    }
  }
`;

export const StyledGenerationSwitcherContainer = styled.div`
  max-width: 216px;
  margin: 12px 20px 0 20px;

  .input {
    cursor: pointer;
    width: 100%;
    min-width: 100%;

    background-color: #1d1b1f;

    .input__field {
      cursor: pointer;
    }
  }
`;
