import React, { FC, ReactNode } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import ComingSoonPage from "../../pages/ComingSoonPage";
import PageContentLayout from "../PageContentLayout/PageContentLayout";
import { IRenderPageRoutes } from "./types";
import ContentText from "../ContentText/ContentText";
import { nonGenPaths } from "../Sidebar/constants";

const RenderPageRoutes: FC<IRenderPageRoutes> = ({
  pagesList,
  sectionInfo,
}) => {
  const {
    id: sectionId,
    subsectionsInfo,
    path: sectionPath,
    name: sectionName,
    storybookLink: sectionStorybookLink,
    storybookVueLink: sectionStorybookVueLink,
    figmaLink: sectionFigmaLink,
    tabs: sectionTabs,
    additionalInfo,
  } = sectionInfo;
  const match = useRouteMatch();
  let pageRoutes: ReactNode[] = [];

  if (subsectionsInfo.length) {
    pageRoutes = subsectionsInfo.reduce<ReactNode[]>(
      (routesComponents, { pagesInfo = [] }) => {
        pagesInfo.forEach(
          ({
            name: pageName,
            id: pageId,
            path: pagePath,
            storybookLink,
            storybookVueLink,
            figmaLink,
            tabs,
            generation,
          }) => {
            const PageComponent =
              pagesList[pagePath.replace("/", "")] || ComingSoonPage;
            const fullPath = `${match.path}${pagePath}`;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const genFromFullPath: any = fullPath.split("/")[1];
            if (
              !generation?.includes(genFromFullPath) &&
              !nonGenPaths.includes(genFromFullPath)
            ) {
              routesComponents.push(
                <Route
                  key={pageId}
                  path={fullPath}
                  render={() => (
                    <PageContentLayout
                      path={fullPath}
                      storybookLink={storybookLink}
                      storybookVueLink={storybookVueLink}
                      figmaLink={figmaLink}
                      tabs={tabs}
                      title={pageName}
                      additionalInfo={additionalInfo}
                    >
                      <ContentText>
                        Такой страницы нет для этого поколения, компонент был
                        переименован или удалён
                      </ContentText>
                    </PageContentLayout>
                  )}
                />
              );
            } else {
              routesComponents.push(
                <Route
                  key={pageId}
                  path={fullPath}
                  render={() => (
                    <PageContentLayout
                      path={fullPath}
                      storybookLink={storybookLink}
                      storybookVueLink={storybookVueLink}
                      figmaLink={figmaLink}
                      tabs={tabs}
                      title={pageName}
                      additionalInfo={additionalInfo}
                    >
                      <PageComponent tabs={tabs} />
                    </PageContentLayout>
                  )}
                />
              );
            }
          }
        );

        return routesComponents;
      },
      []
    );
  } else {
    const PageComponent =
      pagesList[sectionPath.replace("/", "")] || ComingSoonPage;

    pageRoutes = [
      <Route
        key={sectionId}
        path={sectionPath}
        render={() => (
          <PageContentLayout
            path={sectionPath}
            storybookLink={sectionStorybookLink}
            storybookVueLink={sectionStorybookVueLink}
            figmaLink={sectionFigmaLink}
            tabs={sectionTabs}
            title={sectionName}
            additionalInfo={additionalInfo}
            withoutSubsection={subsectionsInfo.length === 0}
          >
            <PageComponent tabs={sectionTabs} />
          </PageContentLayout>
        )}
      />,
    ];
  }

  return <>{pageRoutes}</>;
};

export default RenderPageRoutes;
