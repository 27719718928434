import React, { FC } from "react";
import { DIVIDER_TYPES } from "../../constants/divider";
import { StyledVerticalDivider, StyledHorizontalDivider } from "./styles";
import { IDivider } from "./types";

const Divider: FC<IDivider> = ({
  type = DIVIDER_TYPES.horizontal,
  className,
}) => {
  if (type === DIVIDER_TYPES.vertical) {
    return <StyledVerticalDivider className={className} />;
  }

  return <StyledHorizontalDivider className={className} />;
};
Divider.displayName = "Divider";

export default Divider;
